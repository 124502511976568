const alignJustify = "../../assets/Icons/bold-icons/align-justify.svg";
const bell = "../../assets/Icons/bold-icons/bell.svg";
const box = "../../assets/Icons/bold-icons/box.svg";
const building = "../../assets/Icons/bold-icons/building.svg";
const component = "../../assets/Icons/bold-icons/component.svg";
const externalLink = "../../assets/Icons/bold-icons/external-link.svg";
const eye = "../../assets/Icons/bold-icons/eye.svg";
const folderCog = "../../assets/Icons/bold-icons/folder-cog.svg";
const history = "../../assets/Icons/bold-icons/history.svg";
const home = "../../assets/Icons/bold-icons/home.svg";
const alertCircle = "../../assets/Icons/bold-icons/Icon=alert-circle.svg";
const alertCircleRed = "../../assets/Icons/bold-icons/alert-circle-red.svg";
const alignCenterHorizontal =
  "../../assets/Icons/bold-icons/Icon=align-center-horizontal.svg";
const alignCenterVertical =
  "../../assets/Icons/bold-icons/Icon=align-center-vertical.svg";
const alignEndVertical =
  "../../assets/Icons/bold-icons/Icon=align-end-vertical.svg";
const alignStartVertical =
  "../../assets/Icons/bold-icons/Icon=align-start-vertical.svg";
const ArrowBack = "../../assets/Icons/bold-icons/Icon=Arrow Back.svg";
const arrowBigDown = "../../assets/Icons/bold-icons/Icon=arrow-big-down.svg";
const arrowBigUp = "../../assets/Icons/bold-icons/Icon=arrow-big-up.svg";
const arrowDownNarrowWide =
  "../../assets/Icons/bold-icons/Icon=arrow-down-narrow-wide.svg";
const arrowDownRight = "../../assets/Icons/bold-icons/Icon=arrow-down-right.svg";
const arrowDownStraight =
  "../../assets/Icons/bold-icons/Icon=arrow-down-straight.svg";
const arrowDown = "../../assets/Icons/bold-icons/Icon=arrow-down.svg";
const arrowLeft1 = "../../assets/Icons/bold-icons/Icon=arrow-left (1).svg";
const arrowLeft = "../../assets/Icons/bold-icons/Icon=arrow-left.svg";
const arrowRight = "../../assets/Icons/bold-icons/Icon=arrow-right.svg";
const arrowUpNarrowWide =
  "../../assets/Icons/bold-icons/Icon=arrow-up-narrow-wide.svg";
const arrowUpRight = "../../assets/Icons/bold-icons/Icon=arrow-up-right.svg";
const atSign = "../../assets/Icons/bold-icons/Icon=at-sign.svg";
const calendar = "../../assets/Icons/bold-icons/Icon=calendar.svg";
const checkCheck = "../../assets/Icons/bold-icons/Icon=check-check.svg";
const check = "../../assets/Icons/bold-icons/Icon=check.svg";
const chevronRight = "../../assets/Icons/bold-icons/Icon=chevron-right.svg";
const copy1 = "../../assets/Icons/bold-icons/Icon=copy (1).svg";
const copy = "../../assets/Icons/bold-icons/Icon=copy.svg";
const download = "../../assets/Icons/bold-icons/Icon=download.svg";
const fileText = "../../assets/Icons/bold-icons/Icon=file-text.svg";
const eyeOff = "../../assets/Icons/bold-icons/Icon=eye-off.svg";
const fileUp = "../../assets/Icons/bold-icons/Icon=file-up.svg";
const filter = "../../assets/Icons/bold-icons/Icon=filter.svg";
const folderDown = "../../assets/Icons/bold-icons/Icon=folder-down.svg";
const folderUp = "../../assets/Icons/bold-icons/Icon=folder-up.svg";
const Frame27975 = "../../assets/Icons/bold-icons/Icon=Frame 27975.svg";
const globe = "../../assets/Icons/bold-icons/Icon=globe.svg";
const link1 = "../../assets/Icons/bold-icons/Icon=link (1).svg";
const link = "../../assets/Icons/bold-icons/Icon=link.svg";
const lock = "../../assets/Icons/bold-icons/Icon=lock.svg";
const messageSquare = "../../assets/Icons/bold-icons/Icon=message-square.svg";
const paperclip = "../../assets/Icons/bold-icons/Icon=paperclip.svg";
const phoneCall = "../../assets/Icons/bold-icons/Icon=phone-call.svg";
const printer = "../../assets/Icons/bold-icons/Icon=printer.svg";
const sendAlt = "../../assets/Icons/bold-icons/Icon=send-alt.svg";
const send = "../../assets/Icons/bold-icons/Icon=send.svg";
const settings = "../../assets/Icons/bold-icons/Icon=settings.svg";
const trendingUp = "../../assets/Icons/bold-icons/Icon=trending-up.svg";
const uploadCloud = "../../assets/Icons/bold-icons/Icon=upload-cloud.svg";
const zap = "../../assets/Icons/bold-icons/Icon=zap.svg";
const microscope = "../../assets/Icons/bold-icons/microscope.svg";
const stethoscope = "../../assets/Icons/bold-icons/stethoscope.svg";
const userPlus2 = "../../assets/Icons/bold-icons/user-plus-2.svg";
const exportIcon = "../../assets/Icons/bold-icons/export-Icon.svg";
const branchIcon = "../../assets/Icons/bold-icons/Icons-branches.svg";
const datePicker = "../../assets/Icons/bold-icons/date-picker.svg";
const cloudDown = "../../assets/Icons/bold-icons/cloud-arrow-down.svg";
const webChannelIcon = "../../assets/Icons/bold-icons/globe.svg";
const whatsappChannelIcon = "../../assets/Icons/bold-icons/whatsapp.svg";
const UssdChannelIcon = "../../assets/Icons/bold-icons/ussd-icon.svg";
const mobileChannelIcon = "../../assets/Icons/bold-icons/mobile-icon.svg";
const feedbackIcon = "../../assets/Icons/bold-icons/feedback-icon.svg";
const warning = "../../assets/Icons/bold-icons/warning-icon.svg";
const commendationBranchIcon ="../../assets/Icons/bold-icons/phone-1.svg";

const blank = "../../assets/Icons/bold-icons/blank-l.svg";
const markAsRead = "../../assets/Icons/bold-icons/mark-read.svg";

const redX = "../../assets/Icons/bold-icons/red-cancel.svg";
const workBriefcase = "../../assets/Icons/bold-icons/workbriefcase.svg";
const checkIcon = "../../assets/Icons/bold-icons/check-icon.svg";

const servicePointIcon = "../../assets/Icons/bold-icons/service-point-icon.svg";
const flashMsgIcon = "../../assets/Icons/bold-icons/strike-dark.svg";
const users = "../../assets/Icons/bold-icons/users.svg";
const darkBranch = "../../assets/Icons/bold-icons/dark-branch-icon.svg";
const actionIcon = "../../assets/Icons/bold-icons/more.svg";
const resendInvite = "../../assets/Icons/bold-icons/resend-invite.svg";
const personIcon = "../../assets/Icons/bold-icons/Person.svg";
const emptyUserState = "../../assets/Icons/bold-icons/empty-user-state.svg";
const arrow=  "../../assets/Icons/bold-icons/arrow.svg";
const exclamationTriangle=  "../../assets/Icons/bold-icons/exclamation-triangle.svg";
const bBox=  "../../assets/Icons/bold-icons/b-boxes.svg";
const deactivate=  "../../assets/Icons/bold-icons/deactivate.svg";
const deactivateCircle=  "../../assets/Icons/bold-icons/deactivate-circle.svg";
const verticalDots = "../../assets/Icons/bold-icons/three-dot.svg";
const barChart =  "../../assets/Icons/grayed-out-icons/bar-chart.svg";
const fileWhite =  "../../assets/Icons/grayed-out-icons/file-white.svg";
const pieChart =  "../../assets/Icons/grayed-out-icons/pie-chart.svg";
const rating =  "../../assets/Icons/grayed-out-icons/rating.svg";
const chartArrow =  "../../assets/Icons/grayed-out-icons/chart-arrow.svg";
const darkGreenDot =  "../../assets/Icons/primary-colored-icons/dark-green-dot.svg";
const formEmptyState =  "../../assets/Icons/primary-colored-icons/form-empty-state.svg";
const greenDot =  "../../assets/Icons/primary-colored-icons/green-dot.svg";
const darkOrangeDot =  "../../assets/Icons/primary-colored-icons/dark-orange-dot.svg";
const darkYellowDot =  "../../assets/Icons/primary-colored-icons/dark-yellow-dot.svg";
const redDot =  "../../assets/Icons/primary-colored-icons/red-dot.svg";
const yellowDot =  "../../assets/Icons/primary-colored-icons/yellow-dot.svg";
const purpleDot =  "../../assets/Icons/primary-colored-icons/purple-dot.svg";
const star =  "../../assets/Icons/primary-colored-icons/star.svg";
const ratingEmpty =  "../../assets/Icons/primary-colored-icons/rating-empty.svg";
const homeActive =  "../../assets/Icons/primary-colored-icons/home-active.svg";
const plusCircleWhite = "../../assets/Icons/bold-icons/plus-circle.svg";
const upWhite = "../../assets/Icons/bold-icons/angle-up-white.svg";


const upload_icon = "../../assets/Icons/bold-icons/upload.svg";
const facility_icon = "../../assets/Icons/bold-icons/facility-icon.svg";
const send_sms = "../../assets/Icons/bold-icons/send-sms-icon.svg";
const send_massage = "../../assets/Icons/bold-icons/template-sendmessage-icon.svg";
const alert_circle_danger = "../../assets/Icons/bold-icons/alert-circle-danger.svg";
const white_star = "../../assets/Icons/bold-icons/white-star.svg";
const template_cancel = "../../assets/Icons/bold-icons/template-cancel.svg";
const add_icon = "../../assets/Icons/bold-icons/add+Icons.svg";
const import_black = "../../assets/Icons/bold-icons/import-black.svg";
const export_black = "../../assets/Icons/bold-icons/export-black.svg";
const massage_icon_black ="../../assets/Icons/bold-icons/massage-icon-black.svg";
const download_white_icon = "../../assets/Icons/bold-icons/DownloadIcons.svg";
const responeIcon = "../../assets/Icons/bold-icons/response-icon.svg";
const form_article = "../../assets/Icons/bold-icons/form-article-icon.svg";
const  alert_cirlce = "../../assets/enterprise/bold/alert-circle.svg";
const entexportIcon = "../../assets/enterprise/bold/alert-circle.svg";
const lgaIcon = "../../assets/Icons/enterprise/bold/lga-fac.svg";
const groupUsers = "../../assets/Icons/enterprise/bold/group-users.svg";
const menuIcon = "../../assets/Icons/enterprise/bold/menu.svg";
const lockBack = "../../assets/Icons/enterprise/bold/lock-black.svg";
const eyeBlack = "../../assets/Icons/enterprise/bold/openBlackEye.svg";
const arrOnTriangle= "../../assets/Icons/enterprise/bold/arrow-left-on-rectangle.svg";
const lockIcon= "../../assets/Icons/enterprise/bold/lock-closed.svg";
const userGroup= "../../assets/Icons/enterprise/bold/user-group.svg";

const android = "../../assets/Icons/enterprise/android.svg";
const overViewActive = "../../assets/Icons/enterprise/overview-icon.svg";
const configurationActive = "../../assets/Icons/enterprise/configuration-active.svg";
const facility = "../../assets/Icons/enterprise/bold/facility.svg";
const gender = "../../assets/Icons/enterprise/bold/gender.svg";
const date = "../../assets/Icons/enterprise/bold/date.svg";
const chevronDownWhite = "../../assets/Icons/enterprise/export-down.svg";
const dsort = "../../assets/Icons/enterprise/bold/dsort-original.svg";
const theadArr = "../../assets/Icons/enterprise/bold/th-arrow-up.svg";
const theadDown = "../../assets/Icons/enterprise/bold/th-arrow-down.svg";
const sortDescend = "../../assets/Icons/enterprise/bold/sort-descend.svg";
const boldCalenderDays = "../../assets/Icons/enterprise/bold/calendar-days.svg";
const lgaFac = "../../assets/Icons/enterprise/bold/lga-fac.svg";
const warningHint = "../../assets/Icons/enterprise/bold/lga-alert-circle.svg";
const exportCloud = "../../assets/Icons/enterprise/bold/export-mod.svg";
const feedbackBold = "../../assets/Icons/enterprise/bold/feedback.svg";
const addUser = "../../assets/Icons/enterprise/bold/user-plus.svg";
const boldExclaimation = "../../assets/Icons/enterprise/bold/exclaimation-circle.svg";
const boldExclaimationTrinagle = "../../assets/Icons/enterprise/bold/exclamation-triangle.svg";
const distractor = "../../assets/Icons/enterprise/bold/detractors.svg";
const cake = "../../assets/Icons/enterprise/bold/cake.svg";
const office = "../../assets/Icons/enterprise/bold/offices.svg";
const starC = "../../assets/Icons/enterprise/bold/StarC.svg";



// bold icons end

// gray out start
const archive = "../../assets/Icons/grayed-out-icons/archive.svg";
const arrowLeftRight =
  "../../assets/Icons/grayed-out-icons/arrow-left-right.svg";
const arrowUpDown = "../../assets/Icons/grayed-out-icons/arrow-up-down.svg";
const articleFILL =
  "../../assets/Icons/grayed-out-icons/article_FILL0_wght400_GRAD0_opsz24 1.svg";
const checkCircle2 = "../../assets/Icons/grayed-out-icons/check-circle-2.svg";
const homeWorkFILL0 =
  "../../assets/Icons/grayed-out-icons/home_work_FILL0_wght400_GRAD0_opsz24 1.svg";
const alignLeft = "../../assets/Icons/grayed-out-icons/Icon=align-left.svg";
const alignLeft1 =
  "../../assets/Icons/grayed-out-icons/Icon=align-left (1).svg";
const briefcase = "../../assets/Icons/grayed-out-icons/Icon=briefcase.svg";
const checkSquare2 =
  "../../assets/Icons/grayed-out-icons/Icon=check-square-2.svg";
const chevronDownCircle =
  "../../assets/Icons/grayed-out-icons/Icon=chevron-down-circle.svg";
const chevronDown = "../../assets/Icons/grayed-out-icons/Icon=chevron-down.svg";
const circleDot = "../../assets/Icons/grayed-out-icons/Icon=circle-dot.svg";
const gem = "../../assets/Icons/grayed-out-icons/Icon=gem.svg";
const messagesSquare =
  "../../assets/Icons/grayed-out-icons/Icon=messages-square.svg";
const logOut = "../../assets/Icons/grayed-out-icons/log-out.svg";
const mailOpen = "../../assets/Icons/grayed-out-icons/mail-open.svg";
const minusCircle = "../../assets/Icons/grayed-out-icons/minus-circle.svg";
const moreVertical = "../../assets/Icons/grayed-out-icons/more-vertical.svg";
const pencil = "../../assets/Icons/grayed-out-icons/pencil.svg";
const plusCircle = "../../assets/Icons/grayed-out-icons/plus-circle.svg";
const archplusive = "../../assets/Icons/grayed-out-icons/plus.svg";
const RadioButtonUnchecked =
  "../../assets/Icons/grayed-out-icons/Radio button unchecked.svg";
const refreshCw = "../../assets/Icons/grayed-out-icons/refresh-cw.svg";
const search = "../../assets/Icons/grayed-out-icons/search.svg";
const slash = "../../assets/Icons/grayed-out-icons/slash.svg";
const trash2 = "../../assets/Icons/grayed-out-icons/trash-2.svg";
const user = "../../assets/Icons/grayed-out-icons/user.svg";
const x = "../../assets/Icons/grayed-out-icons/x.svg";
const cancel = "../../assets/Icons/grayed-out-icons/cancel.svg";
const chevronRightGrayed =
  "../../assets/Icons/grayed-out-icons/chevron-right.svg";
const briefCase = "../../assets/Icons/grayed-out-icons/briefcase.svg";
const chevronLeftGrayed =
  "../../assets/Icons/grayed-out-icons/chevron-left.svg";
const warningIcon = "../../assets/Icons/grayed-out-icons/hint.svg";
const feedbackGray =
  "../../assets/Icons/grayed-out-icons/feedback-icon-grey.svg";
const homeIconGray = "../../assets/Icons/grayed-out-icons/home-icon-grey.svg";
const envelopeGray = "../../assets/Icons/grayed-out-icons/envelope.svg";
const openEyeGray = "../../assets/Icons/grayed-out-icons/password-open-eye.svg";
const closeEyeGray =
  "../../assets/Icons/grayed-out-icons/password-closed-eye.svg";
const lockGray = "../../assets/Icons/grayed-out-icons/password-lock-gray.svg";
const xGray = "../../assets/Icons/grayed-out-icons/x-gray.svg";
const emptyStar = "../../assets/Icons/grayed-out-icons/empty-star.svg";
const miniEmptyStar = "../../assets/Icons/grayed-out-icons/mini-empty-star.svg";
const clearSearch = "../../assets/Icons/grayed-out-icons/clear-search-icon.svg";
const dateFilterIcon =
  "../../assets/Icons/grayed-out-icons/filter-date-icon.svg";
const FileInactive = "../../assets/Icons/grayed-out-icons/file-inactive.svg";
const ComputerSettings =
  "../../assets/Icons/grayed-out-icons/computer-settings.svg";
const Switch = "../../assets/Icons/grayed-out-icons/switch.svg";
const briefCase2 = "../../assets/Icons/grayed-out-icons/briefcase.svg";
const pupRight = "../../assets/Icons/grayed-out-icons/pup-right.svg";
const messageSquare2 =
  "../../assets/Icons/grayed-out-icons/messages-square.svg";
const viewAll = "../../assets/Icons/grayed-out-icons/view-all.svg";
const atSignGray = "../../assets/Icons/grayed-out-icons/Icon=at-sign.svg";
const file = "../../assets/Icons/grayed-out-icons/d-file.svg";
const grayBuilding = "../../assets/Icons/grayed-out-icons/gray-building.svg";
const accordionUnchecked =
  "../../assets/Icons/grayed-out-icons/Accordion unchecked.svg";
const activateIcon = "../../assets/Icons/grayed-out-icons/activate.svg";
const deactivateIcon = "../../assets/Icons/grayed-out-icons/deactivate.svg";
const cautionRed = "../../assets/Icons/grayed-out-icons/caution-red.svg";
const cancelInvite = "../../assets/Icons/grayed-out-icons/cancel-invite.svg";
const work = "../../assets/Icons/grayed-out-icons/Work.svg";
const viewIcon = "../../assets/Icons/grayed-out-icons/view-icon.svg";
const threeDts = "../../assets/Icons/grayed-out-icons/three-dot.svg";
const qrCode = "../../assets/Icons/grayed-out-icons/qr-code.svg";
const homeWork = "../../assets/Icons/grayed-out-icons/Home work.svg";
const home_work = "../../assets/Icons/grayed-out-icons/Home-work.svg";
const editBranch = "../../assets/Icons/grayed-out-icons/edit-branch.svg";
const branchIconDark =
  "../../assets/Icons/grayed-out-icons/branch-icon-dark.svg";
const createBranch =
  "../../assets/Icons/grayed-out-icons/create-branch-Icons.svg";
const inActiveBranch =
  "../../assets/Icons/grayed-out-icons/inactive-branch.svg";
const editIcon = "../../assets/Icons/grayed-out-icons/edit.svg";
const branchName = "../../assets/Icons/grayed-out-icons/branch-name-icon.svg";
const mobileselectIcon = "../../assets/Icons/grayed-out-icons/picked-circle.svg";

const grayService = "../../assets/Icons/grayed-out-icons/service-icons.svg";
const optionDot = "../../assets/Icons/grayed-out-icons/three-dot.svg";
const checkboxGray = "../../assets/Icons/grayed-out-icons/Checkbox.svg";
const grayDatePicker = "../../assets/Icons/grayed-out-icons/gray-date-picker.svg";
const greyArrowForward = "../../assets/Icons/grayed-out-icons/gray-arr-rite.svg";
const camera = "../../assets/Icons/grayed-out-icons/camera.svg";
const apple = "../../assets/Icons/enterprise/grey/apple.svg";;
const overviewGray = "../../assets/Icons/enterprise/grey/overview-grey-icon.svg";;
const configuationGray = "../../assets/Icons/enterprise/grey/configuration-icon.svg";;
const usersGray = "../../assets/Icons/enterprise/grey/user-group-grey.svg";;
const grayArrUp = "../../assets/Icons/enterprise/grey/arrow-up-lightgray.svg";;
const grayArrDown = "../../assets/Icons/enterprise/grey/arrow-down-lightgray.svg";;
const greyDot = "../../assets/Icons/enterprise/grey/inactive-circle.svg";;
const xClear = "../../assets/Icons/grayed-out-icons/x-clear.svg";
const hashtag = "../../assets/Icons/enterprise/grey/hashtag.svg";
const starN = "../../assets/Icons/enterprise/grey/StarN.svg";


// gray out end

// primary_colored_icon start
const Polygon5 = "../../assets/Icons/primary-colored-icons/Polygon 5.svg";
const CheckCircle = "../../assets/Icons/primary-colored-icons/Check circle.svg";
const ChevronDown = "../../assets/Icons/primary-colored-icons/chevron-down.svg";
const homeIconWhite =
  "../../assets/Icons/primary-colored-icons/home-icon-white.svg";
const clientshotLogo =
  "../../assets/Icons/primary-colored-icons/clientshot.svg";
const ChevronRight =
  "../../assets/Icons/primary-colored-icons/chevron-right.svg";
const Detractor = "../../assets/Icons/primary-colored-icons/detractor.svg";
const EmptyComplaint =
  "../../assets/Icons/primary-colored-icons/empty-complaints.svg";
const FilledStar = "../../assets/Icons/primary-colored-icons/filled-star.svg";
const MinFilledStar =
  "../../assets/Icons/primary-colored-icons/mini-filled-star.svg";
const InprogressEllipsis =
  "../../assets/Icons/primary-colored-icons/inprogress-ellipsis.svg";
const OpenEllipsis =
  "../../assets/Icons/primary-colored-icons/open-ellipsis.svg";
const Passive = "../../assets/Icons/primary-colored-icons/passive.svg";
const Promoter = "../../assets/Icons/primary-colored-icons/promoter.svg";
const ResolvedEllipsis =
  "../../assets/Icons/primary-colored-icons/resolved-ellipsis.svg";
const CheckActive = "../../assets/Icons/primary-colored-icons/check-active.svg";
const EmptySearchIcon =
  "../../assets/Icons/primary-colored-icons/empty-search-form-icon.svg";
const FileActive = "../../assets/Icons/primary-colored-icons/file-active.svg";
const Plus = "../../assets/Icons/primary-colored-icons/plus.svg";
const Logout = "../../assets/Icons/primary-colored-icons/red-logout.svg";
const NotificationBell =
  "../../assets/Icons/primary-colored-icons/notification-icon.svg";
const message1 = "../../assets/Icons/primary-colored-icons/message.svg";
const EFeedback = "../../assets/Icons/primary-colored-icons/e-feedback.svg";
const msgSqr = "../../assets/Icons/primary-colored-icons/msg-sqr.svg";
const NotificationIcon =
  "../../assets/Icons/primary-colored-icons/notification.svg";
const pAtSign = "../../assets/Icons/primary-colored-icons/p-at-sign.svg";
const pBriefcase = "../../assets/Icons/primary-colored-icons/p-briefcase.svg";
const pChevronRight =
  "../../assets/Icons/primary-colored-icons/p-chevron-right.svg";
const plusCircle2 = "../../assets/Icons/primary-colored-icons/plus-circle.svg";
const accordionChecked =
  "../../assets/Icons/primary-colored-icons/Acccordion checked.svg";

const plusWhiteIcon =
  "../../assets/Icons/primary-colored-icons/plus-white-circle.svg";

const CommendationEmptyState =
  "../../assets/Icons/primary-colored-icons/commendation-empty-state.svg";

const pencilSquare =
  "../../assets/Icons/primary-colored-icons/pencil-square.svg";
const purpleHint =
  "../../assets/Icons/primary-colored-icons/alert-circle-purple.svg";
const purpleSearch =
  "../../assets/Icons/primary-colored-icons/purple-search-icon.svg";
const pBell = "../../assets/Icons/primary-colored-icons/p-bell.svg";
const pArrowRight =
  "../../assets/Icons/primary-colored-icons/p-arrow-right.svg";
const pComment = "../../assets/Icons/primary-colored-icons/p-comment.svg";
const pClose = "../../assets/Icons/primary-colored-icons/p-close.svg";
const calendarDays =
  "../../assets/Icons/primary-colored-icons/calendar-days-2.svg";
const alertCirclePurple =
  "../../assets/Icons/primary-colored-icons/alert-circle-purple.svg";
const clientshotLogoWhite =
  "../../assets/Icons/primary-colored-icons/Clientshot_logo_white.svg";
const activeBranch =
  "../../assets/Icons/primary-colored-icons/active-branch.svg";
const activate = "../../assets/Icons/primary-colored-icons/activate.svg";
const emptyService =  "../../assets/Icons/primary-colored-icons/empty-service-icon.svg";
const addCircle =  "../../assets/Icons/primary-colored-icons/addSectionQuestionIcon.svg";
const checkboxActive =  "../../assets/Icons/primary-colored-icons/Checkbox-active.svg";
const mobileselectedIcon = "../../assets/Icons/primary-colored-icons/picked-circle.svg";
const emptyDeptIcon = "../../assets/Icons/primary-colored-icons/briefcase-1.svg";
const purplePlusCirle = "../../assets/Icons/primary-colored-icons/purple-circle-plus.svg";
const purple_form_icon = "../../assets/Icons/primary-colored-icons/form-article-p-icon.svg";
const suspended_modal = "../../assets/Icons/primary-colored-icons/suspended-modal-icon.svg";
const empty_response = "../../assets/Icons/primary-colored-icons/empty-response.svg";
const cfac = "../../assets/Icons/enterprise/primary/c-fac.svg";
const xcolored = "../../assets/Icons/enterprise/primary/x-colored.svg";
const datecolored = "../../assets/Icons/enterprise/primary/date-colored.svg";
const mobilColored = "../../assets/Icons/enterprise/primary/device-phone-mobile.svg";
const cUsers = "../../assets/Icons/enterprise/primary/c-user.svg";
const cAge = "../../assets/Icons/enterprise/primary/c-age.svg";
const filterSelcted = "../../assets/Icons/enterprise/primary/filter-selected.svg";
const sortcolor = "../../assets/Icons/enterprise/primary/sort-original.svg";
const descendColor = "../../assets/Icons/enterprise/primary/sort-descending.svg";
const purpleArrUp = "../../assets/Icons/enterprise/primary/arrow-up-purple.svg";
const purpleArrDown = "../../assets/Icons/enterprise/primary/arrow-up-purple.svg";
const branchIconP = "../../assets/Icons/primary-colored-icons/branch-icon-p.svg";



const xClearActive = "../../assets/Icons/primary-colored-icons/x-clear-active.svg";
// primary_colored_icon end



// images
const mockup = "../../assets/images/mockup.png";
const hexagon = "../../assets/images/hexagon.svg";
const logo = "../../assets/images/clientshot-login-logo.svg";
const ngnFlag = "../../assets/images/nigeria-flag.svg";
const emailSwift = "../../assets/images/email-swift.svg";
const restart = "../../assets/images/restart.svg";
const commendationEmoji1 = "../../assets/images/5-smiley.svg";
const commendationEmoji2 = "../../assets/images/4-smiley.svg";
const commendationEmoji3 = "../../assets/images/3-smiley.svg";
const complaintEmoji1 = "../../assets/images/2-smiley.svg";
const complaintEmoji2 = "../../assets/images/1-smiley.svg";

const changePhoto = "../../assets/images/change-photo.svg";

const delete_icon = "../../assets/images/delete.svg";
const frame1 = "../../assets/images/Frame-1.svg";
const frame2 = "../../assets/images/Frame-2.svg";
const frame3 = "../../assets/images/Frame-3.svg";
const nigeriaFlag = "../../assets/images/nigeria.svg";
const illustration = "../../assets/images/illustration01.svg";

const deletePhoto = "../../assets/images/delete-photo.svg";
const dateCalender = "../../assets/images/date-calender.svg";
const upload = "../../assets/images/upload.svg";
const deptModalImg = "../../assets/images/department-modal-background.svg";
const ihvnIcon = "../../assets/Icons/enterprise/images/ihvn-icon.svg";
const photoIcon = "../../assets/Icons/enterprise/images/photo.svg";
const noUpload = "../../assets/Icons/enterprise/images/no-upload-icon.svg";



const web = "../../assets/Icons/web.svg";
const toggle_inactive = "../../assets/Icons/toggle-inactive.svg";
const toggle_active = "../../assets/Icons/toggle-active.svg";
const Clientshot_logo = "../../assets/Icons/Clientshot_logo.svg";
const Checkbox_filled_squared =
  "../../assets/Icons/Checkbox-filled-squared.svg";
const Checkbox_empty_squared = "../../assets/Icons/Checkbox-empty-squared.svg";
const whatsapp = "../../assets/Icons/whatsapp.svg";
const phone = "../../assets/Icons/phone.svg";
const copy_white = "../../assets/Icons/copy.svg";
const WSVG = "../../assets/Icons/WSVG.svg";
const qr_code = "../../assets/Icons/qr-code.svg";

const robot = "../../assets/Icons/98042-robot.gif";
const video_mark_up_image = "../../assets/images/video-mark-up-image.png";
const team_work_metaphor_with_puzzle =
  "../../assets/images/Teamwork metaphor with puzzle.png";
const switch_branch = "../../assets/images/Switch-branches-image.svg";
const manage_branch = "../../assets/images/Manage-branches-image.svg";
const fdddepartment = "../../assets/images/FddDepartment.svg";
const aviation_service_point_illustration =
  "../../assets/images/aviation+service+point+illustration.png";
const arrow_left_grayedout = "../../assets/Icons/grayed-out-icons/d-arrow-left.svg";
const pay_check = "../../assets/Icons/bold-icons/pay-check.svg";
const colored_dropdown_icon =
  "../../assets/Icons/primary-colored-icons/template-dropdown-icon.svg";
const search_empty_icon =
  "../../assets/Icons/primary-colored-icons/search-empty.svg";
const insert_icon = "../../assets/Icons/primary-colored-icons/Insert-icon.svg";
const contact_suspended =
  "../../assets/Icons/primary-colored-icons/contact-suspended-icon.svg";
const contact = "../../assets/Icons/primary-colored-icons/contact-icon.svg";
const contact_empty =
  "../../assets/Icons/primary-colored-icons/contact empty state .svg";
const template_box_icon =
  "../../assets/Icons/primary-colored-icons/ template-box-icon.svg";
const check_mark = "../../assets/Icons/primary-colored-icons/check-mark.svg";
const colored_star =
  "../../assets/Icons/primary-colored-icons/colored-star.svg";

const alert_circle_black =
  "../../assets/Icons/grayed-out-icons/alert-circle-black.svg";
const edit = "../../assets/Icons/grayed-out-icons/editpen.svg";
const gray_star = "../../assets/Icons/grayed-out-icons/gray-star.svg";
const servicepoint = "../../assets/Icons/grayed-out-icons/servisepoint.svg";
const import_icon = "../../assets/Icons/grayed-out-icons/import.svg";
const download_purple_icon = "../../assets/Icons/primary-colored-icons/DownloadIcons+purple.svg";
const slightly_smile = "../../assets/images/slightly-smile.svg";
const statusArrow =  "../../assets/Icons/grayed-out-icons/status-arrow.svg";
const departmentFilterIcon =  "../../assets/Icons/primary-colored-icons/department-filter-icon.svg";
const statusFilterIcon =  "../../assets/Icons/primary-colored-icons/strike.svg";
const ihvnLogo =  "../../assets/Icons/primary-colored-icons/ihvn-logo.svg";
const success =  "../../assets/Icons/primary-colored-icons/success.svg";


const alert_triangle  = "../../assets/Icons/bold-icons/alert-triangle.svg";
const cloud_off = "../../assets/Icons/bold-icons/cloud-off.svg";
const ihvnTemplate = "../../assets/Icons/enterprise/images/ihvn-template.png";
const user_group= "../../assets/Icons/enterprise/images/user-group.svg";

// images end


//  Side bar
const sideForm = "../../assets/Icons/grayed-out-icons/forms.svg";
const sideFormActive = "../../assets/Icons/grayed-out-icons/forms-active.svg";
const sideSettings = "../../assets/Icons/grayed-out-icons/icon-account.svg";
const sideSettingsActive = "../../assets/Icons/grayed-out-icons/icon-account-grey.svg";
const sideConfig = "../../assets/Icons/grayed-out-icons/icon-config.svg";
const sideConfigActive = "../../assets/Icons/grayed-out-icons/icon-config-grey.svg";
const sideContact = "../../assets/Icons/grayed-out-icons/icon-contact.svg";
const sideContactActive = "../../assets/Icons/grayed-out-icons/icon-contact-grey.svg";

export const AppIcons = {
  alignJustify: alignJustify,
  bell,
  box,
  building,
  component,
  externalLink,
  folderCog,
  eye,
  history,
  home,
  alertCircle,
  alignCenterHorizontal,
  alignCenterVertical,
  alignEndVertical,
  alignStartVertical,
  ArrowBack,
  arrowBigDown,
  arrowBigUp,
  arrowDownNarrowWide,
  arrowDownRight,
  arrowDownStraight,
  arrowDown,
  arrowLeft1,
  arrowLeft,
  arrowRight,
  arrowUpNarrowWide,
  arrowUpRight,
  atSign,
  calendar,
  checkCheck,
  check,
  chevronRight,
  copy1,
  copy,
  download,
  fileText,
  eyeOff,
  fileUp,
  filter,
  folderDown,
  folderUp,
  Frame27975,
  globe,
  link1,
  link,
  lock,
  messageSquare,
  paperclip,
  phoneCall,
  printer,
  sendAlt,
  send,
  settings,
  trendingUp,
  uploadCloud,
  zap,
  microscope,
  stethoscope,
  userPlus2,
  exportIcon,
  branchIcon,
  archive,
  arrowLeftRight,
  arrowUpDown,
  articleFILL,
  checkCircle2,
  homeWorkFILL0,
  alignLeft,
  alignLeft1,
  briefcase,
  checkSquare2,
  chevronDownCircle,
  chevronDown,
  circleDot,
  gem,
  messagesSquare,
  logOut,
  mailOpen,
  minusCircle,
  moreVertical,
  pencil,
  plusCircle,
  archplusive,
  RadioButtonUnchecked,
  refreshCw,
  search,
  slash,
  trash2,
  user,
  x,
  cancel,
  Polygon5,
  CheckCircle,
  chevronRightGrayed,
  webChannelIcon,
  whatsappChannelIcon,
  UssdChannelIcon,
  mobileChannelIcon,
  ChevronDown,
  briefCase,
  datePicker,
  chevronLeftGrayed,
  cloudDown,
  warningIcon,
  homeIconWhite,
  homeIconGray,
  clientshotLogo,
  envelopeGray,
  openEyeGray,
  closeEyeGray,
  lockGray,
  alertCircleRed,
  warning,
  xGray,
  emptyStar,
  miniEmptyStar,
  ChevronRight,
  Detractor,
  Passive,
  Promoter,
  MinFilledStar,
  FilledStar,
  EmptyComplaint,
  ResolvedEllipsis,
  OpenEllipsis,
  InprogressEllipsis,
  CheckActive,
  EmptySearchIcon,
  FileActive,
  feedbackIcon,
  feedbackGray,
  CommendationEmptyState,
  clearSearch,
  dateFilterIcon,
  commendationBranchIcon,
  FileInactive,
  ComputerSettings,
  Plus,
  Logout,
  Switch,
  NotificationBell,
  message1,
  EFeedback,
  msgSqr,
  NotificationIcon,
  pAtSign,
  pBriefcase,
  pChevronRight,
  briefCase2,
  pupRight,
  messageSquare2,
  viewAll,
  atSignGray,
  work ,
  viewIcon,
  threeDts,
  plusCircle2,
  qrCode,
  pComment,
  pClose,
  nigeriaFlag,
  illustration,
  homeWork,
  exclamationTriangle,
  editIcon,
  editBranch,
  deactivate,
  deactivateCircle,
  createBranch,
  clientshotLogoWhite,
  calendarDays,
  branchName,
  branchIconDark,
  bBox,
  arrow,
  alertCirclePurple,
  activeBranch,
  activate,
  changePhoto,
  delete_icon,
  file,
  blank,
  markAsRead,
  pencilSquare,
  redX,
  workBriefcase,
  purpleHint,
  grayBuilding,
  checkIcon,
  accordionChecked,
  qr_code,
  WSVG,
  copy_white,
  whatsapp,
  phone,
  Checkbox_empty_squared,
  Checkbox_filled_squared,
  Clientshot_logo,
  toggle_active,
  toggle_inactive,
  web,
  servicePointIcon,

  plusWhiteIcon,

  deactivateIcon,
  cautionRed ,
  cancelInvite ,
  flashMsgIcon ,
  users ,
  darkBranch ,
  actionIcon,
  resendInvite,
  personIcon,
  emptyUserState ,
  accordionUnchecked,
  activateIcon,
  pBell,
  inActiveBranch,
  pArrowRight,
  purpleSearch,
  barChart,
  darkGreenDot,
  darkOrangeDot,
  darkYellowDot,
  fileWhite,
  formEmptyState,
  greenDot,
  pieChart,
  ratingEmpty,
  redDot,
  star,
  yellowDot,
  rating,
  purpleDot,
  chartArrow,
  homeActive,
  statusArrow,
  departmentFilterIcon,
  statusFilterIcon,
  arrow_left_grayedout,
  pay_check,
  upload,
  colored_dropdown_icon,
  send_sms,
  send_massage,
  search_empty_icon,
  insert_icon,
  facility_icon,
  contact_empty,
  contact_suspended,
  contact,
  edit,
  servicepoint,
  add_icon,
  import_icon,
  template_box_icon,
  check_mark,
  colored_star,
  alert_circle_black,
  gray_star,
  upload_icon,
  alert_circle_danger,
  white_star,
  template_cancel,
  import_black,
  export_black,
  massage_icon_black,
  download_white_icon,
  download_purple_icon,
  slightly_smile,
  plusCircleWhite,
  eyeBlack,
  grayService,
  optionDot,
  emptyService,
  addCircle,
  checkboxActive,
  checkboxGray,
  form_article,
  purple_form_icon,
  suspended_modal,
  empty_response,
  verticalDots,
  mobileselectIcon,
  mobileselectedIcon,
  emptyDeptIcon,
  purplePlusCirle,
  alert_triangle,
  cloud_off,
  sideForm  ,
  sideFormActive,
  sideSettings,
  sideSettingsActive ,
  sideConfig,
  sideConfigActive ,
  sideContact,
  sideContactActive,
  grayDatePicker,
  responeIcon,
  greyArrowForward,
  home_work,
  xClear,
  xClearActive,
  ihvnLogo,
  success,
  alert_cirlce,
  entexportIcon,
  cfac,
  xcolored,
  datecolored,
  lgaIcon,
  groupUsers,
  menuIcon,
  lockBack,
  arrOnTriangle,
  lockIcon,
  photoIcon,
  camera,
  apple,
  mobilColored,
  android,
  overViewActive,
  overviewGray,
  configurationActive,
  configuationGray,
  usersGray,
  grayArrUp,
  grayArrDown,
  greyDot,
  userGroup,
  cUsers,
  cAge,
  facility,
  gender,
  date,
  filterSelcted,
  sortcolor,
  chevronDownWhite,
  dsort,
  theadArr,
  theadDown,
  sortDescend,
  descendColor,
  purpleArrUp,
  purpleArrDown,
  boldCalenderDays,
  lgaFac,
  warningHint,
  exportCloud,
  feedbackBold,
  addUser,
  boldExclaimation,
  boldExclaimationTrinagle,
  distractor,
  cake,
  office,
  hashtag,
  starC,
  starN,
  upWhite,
  branchIconP

};

export const images = {
  mockup,
  hexagon,
  logo,
  ngnFlag,
  emailSwift,
  restart,
  frame1,
  frame2,
  frame3,
  changePhoto,
  deletePhoto,
  dateCalender,
  upload,
  commendationEmoji1,
  commendationEmoji2,
  commendationEmoji3,
  complaintEmoji1,
  complaintEmoji2,
  robot,
  video_mark_up_image,
  team_work_metaphor_with_puzzle,
  switch_branch,
  manage_branch,
  fdddepartment,
  aviation_service_point_illustration,
  deptModalImg,
  ihvnIcon,
  noUpload,
  ihvnTemplate,
  user_group,

};
